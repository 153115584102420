const axios = require('axios');
const events = {
    data: {
        event_page_view: null,
        event_check_in: null,
        event_rate_my_day: null,
    },
    init() {
        console.log('events init');
        events.methods.assign.on_init();
        events.methods.add_handlers.on_init();
        events.methods.refresh();
        window.events = events;
    },
    methods: {
        assign: {
            on_init() {
                events.data.event_page_view = utils.get_data().urls.eventpageview;
                events.data.event_check_in = utils.get_data().urls.eventcheckin;
                events.data.event_rate_my_day = utils.get_data().urls.eventratemyday;
            },
            on_refresh() {

            },
        },
        add_handlers: {
            on_init() {
                document.addEventListener("ajax-refreshed", () => {
                    events.methods.refresh();
                });
            },
            on_refresh() {

            },
        },
        refresh() {
            events.methods.assign.on_refresh();
            events.methods.add_handlers.on_refresh();
            events.methods.trigger.page_view();
        },
        trigger: {
            page_view() {
                console.log("posting page view");
                axios.post(
                    events.data.event_page_view, {},
                    {
                        params: {
                            post_id: utils.get_data().id
                        }
                    }
                ).then(function (response) {
                    console.log(response);
                });
            },
            check_in(message) {
                console.log("posting check in event");
                axios.post(
                    events.data.event_check_in, {},
                    {
                        params: {
                            message: message,
                        }
                    }
                ).then(function (response) {
                    console.log(response);
                });
            },
            rate_my_day(message) {
                console.log("posting rate my day event");
                axios.post(
                    events.data.event_rate_my_day, {},
                    {
                        params: {
                            message: message,
                        }
                    }
                ).then(function (response) {
                    console.log(response);
                });
            },
        }
    },
};

//events.init();

export { events };