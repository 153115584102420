$ = jQuery.noConflict(); // Allow this whole thing to use $

var modal = {
    defaults: {
        hide_close: false,
        message_is_element: false,
        button: true,
        button_text: "OK",
        button_template:
            "<a class='btn btn--default' href='#' onclick='event.preventDefault();modal.close();'>Ok</a>",
        message: "This is a modal",
        additional_classes: "",
        image: {
            url: null,
            url_2x: null,
            width: null,
            height: null,
            has_dark_background: false
        },
        onClose: null,
    },
    current_args: null,
    borrowed_element_parent: null,
    element: {
        parent_js: null,
        parent: null,
        close: null,
        content: {
            image: null,
            text: null
        },
        footer: null,
        background: null
    },
    events: {
        ready: new Event('modal-ready'),
        open: new Event('modal-open'),
        close: new Event('modal-close'),
    },
    init: function () {
        modal.assign();
        modal.add_handlers();
        modal.close();
        document.dispatchEvent(modal.events.ready);
        window.modal = modal;
    },
    assign: () => {
        modal.element.parent_js = document.getElementById("modal");
        modal.element.parent = $(".modal");
        modal.element.close = modal.element.parent.find(".modal__close");
        modal.element.content.image = modal.element.parent.find(
            ".modal__content__image"
        );
        modal.element.content.text = modal.element.parent.find(
            ".modal__content__text"
        );
        modal.element.footer = modal.element.parent.find(".modal__footer");
        modal.element.background = modal.element.parent.find(".modal__background");
    },
    add_handlers: () => {
        modal.element.close.on("click", function () {
            modal.close();
        });
    },
    open: () => {
        if (modal.element.parent.hasClass('active')) return;
        modal.element.parent.addClass("active");
        document.body.dataset.modal = "active";
        document.dispatchEvent(modal.events.open);
        modal.take_focus();
    },
    close: () => {
        if (!modal.element.parent.hasClass('active')) return;
        modal.element.parent.addClass("leaving");
        document.body.dataset.modal = "";
        document.dispatchEvent(modal.events.close);

        setTimeout(() => {
            modal.do_closed();
        }, 250);
        //}, 1000); // This makes a longer going away animation
    },
    // Make sure the previous state has ended before opening a new one
    do_closed: () => {

        modal.element.parent.removeClass("active leaving");

        // If message is element, we need to return it to where it came from
        if (modal.current_args.message_is_element) {
            modal.return_element(modal.current_args);
        }

        // Fire the onclose event if one exists
        if (modal.current_args.onClose != null) {
            modal.current_args.onClose();
        }

        // Clear the current args
        modal.current_args = null;

        // Clear additional classes (avoids weirdness when moving from modal type to modal type)
        modal.remove_additional_classes();

        modal.lose_focus();
    },
    set: (args) => {

        console.log(args);

        var new_defaults = {
            hide_close:
                args.hide_close != null ? args.hide_close : modal.defaults.hide_close,
            message_is_element: args.message_is_element != null ? args.message_is_element : modal.defaults.message_is_element,
            button: args.button != null ? args.button : modal.defaults.button,
            button_text:
                args.button_text != null ? args.button_text : modal.defaults.button_text,
            button_template:
                args.button_template != null
                    ? args.button_template
                    : modal.defaults.button_template,
            message: args.message != null ? args.message : modal.defaults.message,
            image: args.image != null ? args.image : modal.defaults.image,
            additional_classes: args.additional_classes != null ? args.additional_classes : modal.defaults.additional_classes,
            onClose: args.onClose != null ? args.onClose : modal.defaults.onClose
        };

        // Set the current args
        modal.current_args = new_defaults;

        modal.set_additional_classes(new_defaults);
        modal.set_buttons(new_defaults);
        modal.set_message(new_defaults);
        modal.set_close(new_defaults);
        modal.set_image(new_defaults);
        modal.open();
    },
    set_image: (args) => {
        if (args.image.url === null) {
            modal.clear_image();
        } else {
            modal.replace_image(args);
        }
    },
    set_additional_classes: (args) => {
        modal.element.parent.attr("data-additional-classes", args.additional_classes);
    },
    remove_additional_classes: () => {
        console.log('removing additional classes');
        modal.element.parent.attr("data-additional-classes", "");
    },
    clear_image: () => {
        modal.element.parent.removeClass("modal--has-image");
        modal.element.parent.removeClass("modal--has-dark-image");
        modal.element.content.image.find("*").remove();
    },
    replace_image: (args) => {
        if (args.image.url === null) {
            modal.clear_image();
        } else {
            modal.clear_image();
            modal.element.parent.addClass("modal--has-image");
            modal.element.content.image.append(modal.build_image(args));
            if (args.image.has_dark_background === "true") {
                modal.element.parent.addClass("modal--has-dark-image");
            }
        }
    },
    build_image: (args) => {
        var image = "<picture>";

        image +=
            "<img src='" +
            args.image.url +
            "' width='" +
            args.image.width +
            "' height='" +
            args.image.height +
            "' loading='lazy'/>";
        image += "</picture>";

        return image;
    },
    set_message: (args) => {
        if (args.message_is_element == true) {
            modal.borrow_element(args);
        } else {
            modal.element.content.text.html(args.message);
        }
    },
    borrow_element: (args) => {
        // Get element
        let borrowed_elem = document.querySelector(args.message);
        // Get element's parent
        modal.borrowed_element_parent = borrowed_elem.parentElement;
        // Place borrowed element in modal
        modal.element.content.text.html(borrowed_elem);
    },
    return_element: (args) => {
        // Recall modals parent element and place it back (this should be inside the modal)
        let current_borrowed_element = document.querySelector(args.message);
        // console.log(current_borrowed_element);
        // console.log(modal.borrowed_element_parent);
        // Return it to the parent element
        modal.borrowed_element_parent.appendChild(current_borrowed_element);
        // Clear out borrowed element parent
        modal.borrowed_element_parent = null;
    },
    set_buttons: (args) => {
        // If there are no buttons, just cleare them all out
        // Otherwise just replace the buttons
        if (args.button === false) {
            modal.clear_buttons();
        } else {
            // We replace the button every time
            modal.replace_buttons(args.button_template);
        }

        // Only update the default button text
        modal.element.footer.find(".btn--default").text(args.button_text);
    },
    clear_buttons: () => {
        modal.element.footer.find(".btn").remove();
    },
    replace_buttons: (new_buttons) => {
        if (new_buttons == null) {
            return;
        }
        modal.clear_buttons();
        modal.element.footer.append(new_buttons);
    },
    set_close: (args) => {
        if (args.hide_close === true) {
            modal.element.close.css({ display: "none" });
        } else {
            modal.element.close.css({ display: "block" });
        }
    },
    // Show the close button programatically
    show_close: () => {
        modal.set_close(false);
    },
    lose_focus: () => {
        modal.element.parent_js.setAttribute("aria-hidden", "true");
        $("main, footer, header").attr("tabindex", "");
        modal.element.parent.find("a, button").attr("tabindex", "-1");
        // focusLock.off(modal.element.parent_js);
    },
    take_focus: () => {
        modal.element.parent_js.setAttribute("aria-hidden", "false");
        $("main, footer, header").attr("tabindex", "-1");
        modal.element.parent.find("a, button").attr("tabindex", "0");
        // focusLock.on(modal.element.parent_js);
    },
};

modal.init();

export { modal };