import { gsap } from "gsap";

var animator = {
    data: {
    },
    // Hide current page content
    remove_current_page: (next_page_data) => {
        //console.log("next: ", next_page_data.next.container);

        let timeline = gsap.timeline();
        return timeline
            .to(".eh__content .change, .ec",
                {
                    opacity: 0,
                    duration: 0.25,
                    ease: "back.in(0.5)"
                },
                0
            );
    },
    // Show next page content
    show_next_page: () => {

        let timeline = gsap.timeline();

        return timeline
            .fromTo(".eh__content .change, .ec",
                {
                    opacity: 0
                },
                {
                    opacity: 1,
                    duration: 0.25,
                    ease: "back.out(0.5)"
                },
                0
            );
    },
    // Run on init
    initial_animations: () => {
        window.animator = animator;
        //animator.show_next_page();
        animator.refresh_animations();
    },
    // Run on page load
    refresh_animations: () => {

    },
}

export { animator };