import barba from '@barba/core';
import 'lazysizes';
import nprogress from 'nprogress/nprogress.js'
import { utils } from './modules/utils';
//import { events } from './modules/events';
import { animator } from './modules/animator';

import { modal } from './modules/modal';
//import { cim } from './modules/check-in-modal';
//import { reminder_list } from './modules/reminders-list';
//import { reminders } from './modules/reminders';
//import { task_lists } from './modules/task-lists';
//import { task_list } from './modules/task-list';
//import { task_list_single } from './modules/task-list-single';
//import { goals } from './modules/goals';
//import { goals_confirmation } from './modules/goals-confirmation';
import { form_manager } from './modules/forms';
//import { rmd } from './modules/rmd';
import { Other } from './modules/other';
import { embeds_manager } from './modules/embed';

const axios = require('axios');

/* = Globals ================================================== */

$ = jQuery.noConflict(); // Allow this whole thing to use $

var ELEMENTS = [
  {
    type: Other,
    element: ".option--other"
  }
];

var uma = null;
var uim = null;

/* = AJAX UI manager ================================================== */

uma = {
  events: {
    initialized: new CustomEvent('ajax-initialized'),
    refreshed: new CustomEvent('ajax-refreshed'),
    changed: new CustomEvent('ajax-changed'),
    load_modules: new CustomEvent('load-modules'),
  },
  init: () => {
    uma.mount();
    setTimeout(function () {
      uma.clear_excludes();
    }, 1);
    document.dispatchEvent(uma.events.initialized);
    document.getElementById('mgmt').dispatchEvent(uma.events.initialized);
    nprogress.configure({ showSpinner: false, parent: "#main-content" });
    window.uma = uma;
  },
  mount: function () {
    console.log('mounting ajax manager');
    barba.init({
      timeout: 5000,
      sync: false,
      debug: false,
      preventRunning: true,
      prevent: ({ el }) => el.classList && el.classList.contains('quicklinks'),
      transitions: [{
        name: 'default-transition',
        async leave(data) {
          //console.log('leaving');
          // Wait for animation to finish before moving to enter
          await animator.remove_current_page(data);
        },
        enter(data) {
          animator.show_next_page();
        }
      }]
    });

    barba.hooks.after((data) => {
      uma.set_state("");
      document.querySelector('html').classList.remove('is-transitioning');

      // Adds next page classes to the body
      document.body.className = "";
      let next_classlist = data.next.container.getElementsByClassName('silo-data')[0].dataset.classes;

      // Had to do this with jquery because to add more than one class with vanilla js, it needs to be formatted like this:
      // document.body.classList.add('class1', 'class2', 'class3');
      // And I do not have time to get this working right now.
      jQuery('body').addClass(next_classlist);

      uma.on_load(data.next);
      uma.clear_excludes();
      animator.refresh_animations();
    });
    barba.hooks.before((data) => {
      document.querySelector('html').classList.add('is-transitioning');
      uma.on_click();
      nprogress.start();
    });
    barba.hooks.enter(() => {
      nprogress.done();
      window.scrollTo(0, 0);
      document.getElementById("main-content").scrollTo(0, 0);
    });
  },
  // Scripts to update wordpress admin bar
  update_wp_admin_bar: (data) => {
    if (!$("body").hasClass("logged-in")) return;
    var returned = $(data.html).filter('#wpadminbar').html();
    $("#wpadminbar").html(returned);
    uma.clear_excludes();
  },
  on_click: () => {

  },
  on_load: (new_page_data) => {
    document.dispatchEvent(uma.events.refreshed);
    document.getElementById('mgmt').dispatchEvent(uma.events.refreshed);

    uma.update_wp_admin_bar(new_page_data);
    uim.onLoadScripts();
  },
  // use barba to navigate to a specific url
  go: (url) => {
    barba.go(url);
  },
  // Set the state of the app
  set_state: (state) => {
    document.body.dataset.state = state;
  },
  // Remove djax links from page
  clear_excludes: () => {
    if (document.getElementById('wpadminbar') === null) return;
    document.getElementById('wpadminbar').setAttribute('data-barba-prevent', 'all');
  },
  // Log the user out
  logout: () => {
    uma.set_state("processing");

    window.events.postAction({type:'logout'});

    axios.post(utils.get_data().urls.postlogout)
      .then(function (response) {
        console.log("RESPONSE: ", response);
        // Forward the user to logout
        uma.go(utils.get_data().urls.start);
        location.reload();
      })
      .catch(function (error) {
        console.log(error);
        uma.set_state("active");
        // Can't logout
      });
  }
};

/* = UI Manager ================================================== */

uim = {
  screenWidth: window.innerWidth,
  elementChecks: ELEMENTS,

  init: () => {
    console.log("UI Manager is now initiated");
    animator.initial_animations();
    uma.init();
    uim.initialLoadScripts();
    uim.onLoadScripts();
    document.dispatchEvent(uma.events.load_modules);
    window.uim = uim;
  },

  // Iterate through elementChecks array
  runChecks: (elements) => {
    //console.log("|__ running checks");

    for (var i = 0; i < elements.length; i++) {
      console.log(
        "  |__ running check on: " + elements[i].element
      );
      if (document.body.contains(document.querySelector(elements[i].element)))
        console.log("    |__ check passed");
      let elems = document.querySelectorAll(elements[i].element);

      for (let index = 0; index < elems.length; index++) {
        const element = elems[index];
        uim.assign(element, elements[i].type);
      }
    }
  },

  // assign objects to elements
  assign: (elem, type) => {
    //console.log("      |__ creating object ...");
    var new_obj = new type(elem);
    new_obj.init();
  },

  // Only load these scripts initially
  initialLoadScripts: () => {
    console.log("|__ running initial scripts");
  },

  // onload functions that load on every page
  onLoadScripts: () => {
    console.log("|__ running onload scripts");
    uim.runChecks(ELEMENTS);
  },
};
uim.init();