import moment from "moment";
//import moment from "moment-timezone";

var utils = {
    // Get silo data for current page
    get_data() {
        return JSON.parse(document.getElementById("silo-data").dataset.all);
    },
    init: () => {
        window.utils = utils;
    },
    methods: {
        get_date_full: (timestring) => {

            // The timestring has my personal timezone offset built into it :[
            let ms = timestring * 1000;
            // Just plain moment() includes your local datetime
            // let date = moment(ms);
            // Adding the utc() parses the time without local datetime
            let date = moment.utc(ms);
            let formatted_date = date.format('MM/DD/YYYY hh:mm a');

            return formatted_date;
        },
        get_date: (timestring) => {
            return moment.utc(timestring * 1000).format('MM/DD/YYYY');
        },
        get_time: (timestring) => {
            return moment.utc(timestring * 1000).format('h:mm a');
        },
        // Get the current time and date as a utf
        get_time_and_date: () => {
            return moment().unix();
        },
        // Get a query string from the url
        get_param(name) {
            var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
            return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
        },
        // Turn a string into a slug
        sluggify (str) {
            str = str.replace(/^\s+|\s+$/g, ''); // trim
            str = str.toLowerCase();
          
            // remove accents, swap ñ for n, etc
            var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
            var to   = "aaaaeeeeiiiioooouuuunc------";
            for (var i=0, l=from.length ; i<l ; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
            }
        
            str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                .replace(/\s+/g, '-') // collapse whitespace and replace by -
                .replace(/-+/g, '-'); // collapse dashes
        
            return str;
        },
    }
}

utils.init();

export { utils }