const embeds_manager = {
    data: {
        target: ".embed--required",
        player: null,
    },
    elements: {
        parent: null,
        target: null,
        video: null,
        button: null,
    },
    init: () => {
        window.embeds_manager = embeds_manager;
        embeds_manager.methods.assign.on_init();
        embeds_manager.methods.add_handlers.on_init();
        embeds_manager.methods.refresh();
    },
    methods: {
        assign: {
            on_init() {
                embeds_manager.methods.assign.on_refresh();
            },
            on_refresh() {
                if (document.body.contains(document.querySelector(embeds_manager.data.target)) === false) return;
                // Find the target
                embeds_manager.elements.target = document.querySelector(embeds_manager.data.target);
                // Find the container entry content element to the target
                embeds_manager.elements.parent = embeds_manager.elements.target.closest('.ec');
                // Find the iframe within the embed panel
                embeds_manager.elements.video = embeds_manager.elements.target.querySelector('iframe');
                // Find the submit button somewhere within the current entry content
                embeds_manager.elements.button = embeds_manager.elements.parent.querySelector('.form__submit');
            },
        },
        add_handlers: {
            on_init: () => {
                document.addEventListener("ajax-refreshed", () => {
                    embeds_manager.methods.refresh();
                });
            },
            on_refresh: () => {

            },
        },
        refresh: () => {
            if (document.body.contains(document.querySelector(embeds_manager.data.target)) === false) return;
            console.log('required embed found');
            // Assign elements
            embeds_manager.methods.assign.on_refresh();
            // Disable button
            embeds_manager.methods.disable_button();
            // Turn on JS API for embed & add handler
            embeds_manager.methods.init_video_api();            
        },
        init_video_api: () => {
            if (embeds_manager.elements.video === null) return;
            console.log('embed manager, starting video api');
            embeds_manager.player = new Vimeo.Player(embeds_manager.elements.video);
            embeds_manager.player.on('play', embeds_manager.methods.enable_button);
        },
        enable_button: () => {
            if (embeds_manager.elements.button === null) return;
            console.log('embed manager, enabling button');
            embeds_manager.elements.button.disabled = false;
        },
        disable_button: () => {
            if (embeds_manager.elements.button === null) return;
            console.log('embed manager, disabling button');
            embeds_manager.elements.button.disabled = true;
        },
    }
}

embeds_manager.init();

export { embeds_manager };