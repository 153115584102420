// import { navigation } from "./navigation";

import { events } from "./events";

const pristine = require("pristinejs");
const axios = require('axios');

const form_manager = {
    data: {
        pristine: null,
        validation_config: {
            // class of the parent element where the error/success class is added
            classTo: 'input',
            errorClass: 'input--error',
            successClass: 'input--success',
            // class of the parent element where error text element is appended
            errorTextParent: 'input',
            // type of element to create for the error text
            errorTextTag: 'div',
            // class of the error text element
            errorTextClass: 'input__help'
        },
        submit_handler: () => { return form_manager.methods.submit_data() },
        submit_url: null,
        type: 'default',
    },
    elements: {
        form: null,
        inputs: null,
        submit: null,
    },
    init: () => {
        window.form_manager = form_manager;
        form_manager.methods.assign();
        form_manager.methods.add_handlers();
        form_manager.methods.check_for_form();
    },
    methods: {
        assign: () => {
            let data = document.getElementById("silo-data").dataset.all;
            form_manager.data.submit_url = JSON.parse(data).urls.postuserdata;
        },
        add_handlers: () => {
            document.addEventListener("ajax-refreshed", () => {
                form_manager.methods.check_for_form();
            });
        },
        add_input_handlers: () => {
            if (form_manager.elements.inputs.length === 0) return;

            form_manager.elements.inputs.forEach(input => {
                let type = input.dataset.watchEvent;
                input.addEventListener(type, () => {
                    form_manager.methods.validate();
                })
            });
        },
        add_submit_handler: () => {
            // Highjack next button
            // navigation.elements.button_next.addEventListener("click", form_manager.data.submit_handler);
        },
        remove_submit_handler: () => {
            // navigation.elements.button_next.removeEventListener("click", form_manager.data.submit_handler);
        },
        check_for_form: () => {
            if (document.querySelector(".form--x") == null) return;

            // Find new form elements
            form_manager.elements.form = document.querySelector(".form--x");
            form_manager.elements.inputs = form_manager.elements.form.querySelectorAll('[required]');
            console.log('form inputs required: ', form_manager.elements.inputs);
            form_manager.elements.submit = form_manager.elements.form.querySelector('.form__submit');

            // Get form type
            form_manager.data.type = form_manager.elements.form.dataset.type;

            // Add validation to form
            form_manager.data.pristine = new pristine(form_manager.elements.form, form_manager.data.validation_config, true);

            // Add handlers to form and highjack navigation button
            form_manager.methods.add_input_handlers();
            form_manager.methods.add_submit_handler();

            // Clear any backend fields we need to clear
            form_manager.methods.clear_existing_data();

            // Run initial validation silently
            // form_manager.data.pristine.validate(form_manager.elements.inputs, true);
            form_manager.methods.validate(true);
        },
        validate: (silent = false) => {
            let is_valid = form_manager.data.pristine.validate(form_manager.elements.inputs, silent);
            //console.log("FORMS: VALIDATE", is_valid);
            if (is_valid) {
                // navigation.methods.set_next(navigation.data.nav.next);
                // navigation.methods.next_remove_event();
                form_manager.elements.submit.disabled = false;
            } else {
                // navigation.methods.clear();
                // navigation.methods.next_reset_event();
                form_manager.elements.submit.disabled = true;
            }
        },
        // Pass along push keys and push up blank data to the database
        clear_existing_data: (push_keys) => {
            if (document.querySelectorAll('.clearkey').length === 0) return;
            let keys = document.querySelectorAll('.clearkey');
            let data = [];
            keys.forEach(item => {
                let key = item.dataset.key;
                let value = "";
                let data_item = {
                    key,
                    value,
                };
                data.push(data_item);
            });
            axios.post(form_manager.data.submit_url, data);
        },
        submit_data: () => {
            console.log("FORMS: SUBMITTING DATA");

            let data = [];

            // TODO: Break out validation into validation types,
            //       This will clean up the code
            form_manager.elements.inputs.forEach(input => {

                // If it's a radio button and it's not checked, don't add it's data
                if (input.classList.contains('radio') && input.checked === false) return;
                if (input.classList.contains('checkbox') && input.checked === false) return;

                // Get key name
                let key = input.dataset.pushKey;
                // Get value
                let value = input.value;
                // Create inner array
                let data_item = {
                    key,
                    value,
                };
                data.push(data_item);
            });

            console.log("FORMS: DATA", data);

            // Set the processing state
            uma.set_state("processing");

            axios.post(form_manager.data.submit_url, data)
                .then(function (response) {
                    console.log("FORMS: SUBMITTING DATA SUBMITTED", response);

                    // Remove the submit handler after submission
                    // form_manager.methods.remove_submit_handler();
                    // navigation.methods.go_next();

                    // TODO:
                    // I think we need to have the next be conditional so that if there are errors we can't go forward
                    // But it also MIGHT not matter because the button is hidden when the form doesn't validate

                    // Navigate the button to next
                    // This isn't added currently because we don't remove the existing handler
                    // navigation.methods.go_next();

                    form_manager.methods.on_complete();

                })
                .catch(function (error) {
                    console.log("FORMS: SUBMITTING DATA ERROR", error);
                    // Clear the state, allow the form to be used again
                    // uma.set_state("");
                });
        },
        on_complete() {
            // THIS FORM TYPE DOESN'T EXIST ON THIS APP
            if (form_manager.data.type === "checkin") {
                // Do checkin-specific stuff (it's a conditional url)
                uma.set_state("");
                let value = form_manager.elements.form.querySelector('input:checked').value;
                let url_ready = form_manager.elements.form.querySelector('input[name="check_in_ready_url"]').value;
                let url_not_ready = form_manager.elements.form.querySelector('input[name="check_in_not_ready_url"]').value;

                // Pass check in event here
                events.methods.trigger.check_in(value);

                if (value === "Very ready") {
                    uma.go(url_ready);
                } else {
                    uma.go(url_not_ready);
                }

            // What to do after other forms
            } else {
                // Figure out how we're going to go to the next page
                if (form_manager.elements.form.dataset.onSubmitUrl !== "") {
                    uma.go(form_manager.elements.form.dataset.onSubmitUrl)
                } else {
                    uma.go(utils.get_data().navigation.next);
                }
                uma.set_state("");
            }
        },
    },
}

// TODO: need to validate on keyup for other field :/
pristine.addValidator("not-blank", function (value) {
    let input = document.querySelector('[value="' + value + '"]');
    // Get parent and checked
    let checked = input.closest('.field__options').querySelector('input:checked');

    // Look for checked input
    // Verify that checked input is not blank
    let returned = true;

    //console.log(checked.value);
    if (checked.value === "") {
        return false;
        //console.log("value is blank");
    } else {
        //console.log("value is not blank");
    }
    return returned;

}, "Please type in a value for Other.", 5, false);

form_manager.init();

export { form_manager }