// TODO:  Add a pristine validator to check for blank inputs on radio button values
function Other(elem) {
    var self = this;
    var group_name = elem.querySelector('.other-activator').name;
    var group = document.querySelectorAll('input[name="' + group_name + '"]');
    var button = elem.querySelector('.other-activator');
    var input = elem.querySelector('.input--other');
    this.init = () => {
        console.log("OTHER: INIT");
        self.add_handlers();
        self.set_value("");
        self.clear();
        self.evaluate();
    };
    
    this.add_handlers = () => {
        console.log("OTHER: ADD HANDLERS");

        // Target the entire group by the name of the input activator
        // This allows us to track when the activator is unchecked
        group.forEach((input) => {
            input.addEventListener("change", self.evaluate);
        });

        input.addEventListener("keyup", () => {
            self.set_value(input.value);
            // Tell the form to validate once you start typing in this field
            //forms.methods.validate();
        });
    };
    // Evaluate whether or not we should show the other field
    this.evaluate = () => {
        if (button.checked === true) {
            self.set_active();
        } else {
            self.set_disabled();
        }
    };
    // Set the other field active
    this.set_active = () => {
        console.log("other input is checked");
        elem.classList.add("active");
        input.focus();
    };
    // Hide the other field
    this.set_disabled = () => {
        console.log("other input is not checked");
        elem.classList.remove("active");
        self.set_value("");
        self.clear();
    };
    this.set_value = (val) => {
        button.value = val;
    };
    this.clear = () => {
        input.value = "";
    };
}

export { Other };